<template>
    <CompTable title="街镇整体内容发布" :columns="columns" :table-api="api" :dataBefore="onDataBefore" @on-reset="onReset">
        <template v-slot:search="evt">
            <Input v-model="evt.search.title" placeholder="请输入标题" />
            <LiefengCascaderCity isClear orgCode="4401" :resetNum="0" ref="cascaderCity" @changeCasader="onChangeCasader" width="250"></LiefengCascaderCity>
            <DatePicker type="daterange" v-model="evt.search.date" placeholder="选择日期范围" style="width: 300px" />
            <ElemCheck
                v-model="evt.search.status"
                :list="[
                    { name: '已发布', value: '4' },
                    { name: '已过期', value: '5' },
                ]"
            ></ElemCheck>
        </template>
    </CompTable>
</template>

<script>
import CompTable from "../../jointly/components/comp-table.vue"
import ElemCheck from "../../customform/components/ElemCheck"
import LiefengCascaderCity from "@/components/LiefengCascaderCity"

export default {
    components: { CompTable, ElemCheck, LiefengCascaderCity },

    data() {
        return {
            api: `/info/api/pc/information/v2/searchOrgScopePage?searchOrgScopeCode=${parent.vue.loginInfo.userinfo.orgCode}&statusList=4,5`,
            columns: [
                {
                    title: "所属栏目",
                    key: "columnName",
                    render: (h, params) =>
                        params.row.columnName
                            ? h(
                                  "Button",
                                  {
                                      attrs: {
                                          type: "info",
                                          size: "small",
                                      },
                                  },
                                  params.row.columnName
                              )
                            : "-",
                },
                {
                    title: "信息标题",
                    key: "title",
                },
                {
                    title: "创建时间",
                    key: "gmtCreate",
                    width: 200,
                },
                {
                    title: "发布范围",
                    key: "scopeNames",
                },
                {
                    title: "发布状态",
                    key: "status",
                    render: (h, params) => ["待审核", "已驳回", "待发布", "已发布", "已过期", "已归档"][params.row.status - 1] || "-",
                },
            ],
        }
    },

    methods: {
        onChangeCasader(evt) {
            if (evt.length === 3 && !this.initial) {
                this.$refs.cascaderCity.changeClear()
                this.initial = true
                this.casader = null
                return
            }

            this.casader = evt
        },

        onReset() {
            this.$refs.cascaderCity.changeClear()
        },

        onDataBefore(data) {
            data = JSON.parse(JSON.stringify(data))

            if (data.date) {
                data.startGmtDate = this.$core.formatDate(new Date(data.date[0]), "yyyy-MM-dd")
                data.endGmtDate = this.$core.formatDate(new Date(data.date[1]), "yyyy-MM-dd")
                delete data.date
            }

            return {
                ...data,
                dataScopeIdList: this.casader?.length > 0 ? "44-4401-" + this.casader.join("-") : null,
            }
        },
    },
}
</script>
